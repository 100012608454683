import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import cx from "classnames";

type Props = React.PropsWithChildren & {
  open: boolean;
  onClose(): void;
  className?: string;
};

const Modal: React.FC<Props> = (props) => {
  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog onClose={props.onClose} className="fixed inset-0 flex">
        <Transition.Child
          enter="ease-out"
          leave="ease-in delay-100"
          enterFrom="opacity-0"
          leaveTo="opacity-0"
          className="absolute inset-0 bg-black/30 transition-opacity duration-200"
        />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          leave="ease-in duration-200"
          enterFrom="opacity-0 scale-95"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel
            className={cx(
              "relative mx-2 my-auto w-full overflow-hidden md:mx-auto md:max-w-3xl",
              "rounded-md transition-all",
              props.className
            )}
          >
            <button
              className="absolute top-0 right-0 m-2"
              onClick={props.onClose}
              children={<XMarkIcon className="h-5 w-5" />}
            />
            <div>{props.children}</div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default Modal;
