import { HomeHero, HowItWorks } from "@wisecards/site-components";

const isProd = import.meta.env.MODE === "production";

const Home: React.FC = () => {
  return (
    <div className="my-auto">
      <HomeHero />
      {!isProd && <HowItWorks />}
    </div>
  );
};

export default Home;
