//import { api } from "@wisecards/utils";
import axios, { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { ActionFunction, json } from "react-router-dom";

type APIError = AxiosError<{ error?: { status: string; message: string } }>;

export const contactAction: ActionFunction = async ({ request }) => {
  if (request.method === "POST") {
    const form = await request.formData();
    const data = {
      id: "contact-beta",
      name: form.get("name")?.toString(),
      email: form.get("email")?.toString(),
      message: form.get("message")?.toString(),
    };

    return toast
      .promise(
        axios.post("/api/message", { data }),
        {
          loading: "Sending message…",
          success: "Thank you for contacting us.\nWe will be in touch soon.",
          error: (d: APIError) =>
            d.response?.data.error?.message || "Oops! Something went wrong.",
        },
        { duration: 5000 }
      )
      .then(() => json({ ok: true }))
      .catch(() => json({ ok: false }));
  }
  throw new Response("not implemented", { status: 401 });
};
