import { contactAction } from "@wisecards/site-router";
import { AppLayout, Home } from "@wisecards/site-screens";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./index.css";

console.info(`v${import.meta.env.VITE_PKG_VERSION} (${import.meta.env.MODE})`);

createRoot(document.getElementById("app")!).render(
  <RouterProvider
    router={createBrowserRouter(
      createRoutesFromChildren(
        <Route element={<AppLayout />}>
          <Route path="*" action={contactAction} element={<Home />} />
        </Route>
      )
    )}
  />
);
