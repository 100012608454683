import { CheckCircleIcon } from "@heroicons/react/24/outline";
import ButtonCreateCard from "./ButtonCreateCard";
import cx from "classnames";

const features = [
  "Design your card to match your brand or personality.",
  "Runs on your smartphone, no apps required.",
  "Instantly share your details with the people you meet.",
  "We plant a tree for every card created.",
];

const HomeHero: React.FC = () => {
  return (
    <div
      className={cx(
        "container mx-auto my-10 min-h-[400px]",
        "flex flex-col-reverse gap-4 md:gap-6 lg:gap-10",
        "lg:grid lg:grid-cols-2"
      )}
    >
      <div className="space-y-10 lg:space-y-20">
        <h1 className="text-4xl font-bold md:text-5xl xl:text-6xl">
          The smart digital business card
        </h1>
        <ul
          className={cx(
            "my-4 my-10 grid gap-4 text-sm",
            "md:grid-cols-2 md:text-base"
          )}
        >
          {features.map((f, i) => (
            <li key={i} className="flex items-center gap-3 md:items-start">
              <CheckCircleIcon className="h-6 w-6" />
              <h4 className="flex-1">{f}</h4>
            </li>
          ))}
        </ul>
        <ButtonCreateCard />
      </div>
      <div
        className="rounded-xl bg-cover pb-[50%]"
        style={{ backgroundImage: `url(/ogimage.jpg)` }}
      />
    </div>
  );
};

export default HomeHero;
