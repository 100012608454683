import { Logo, Modal } from "@wisecards/components";
import cx from "classnames";
import { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";
import phoneImg from "../assets/beta-phone.png";

const ButtonCreateCard: React.FC<{ className?: string }> = (props) => {
  const { Form, state, data } = useFetcher();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (state === "idle" && data?.ok) setOpen(false);
  }, [state, data]);

  return (
    <>
      <button
        className={cx("button", props.className)}
        onClick={() => setOpen(true)}
      >
        Create a card
      </button>
      <Modal open={open} onClose={() => setOpen(false)} className="bg-white">
        <div className="flex">
          <div className="hidden w-full flex-col md:flex">
            <Logo className="mx-auto my-4 w-40" />
            <div
              className="mx-auto w-full flex-1 bg-cover bg-no-repeat pb-[90%]"
              style={{ backgroundImage: `url(${phoneImg})` }}
            />
          </div>
          <div className="bg-accent-50 space-y-4 p-10 text-center">
            <h2 className="text-2xl font-bold">In beta, nearly there.</h2>
            <p className="text-sm text-gray-700">
              You can’t create your own cards just yet, but we are still working
              with brands to set up and use cards on their behalf. Let us help
              you get started.
            </p>
            <Form className="space-y-6" method="post">
              <input
                required
                name="name"
                className="field"
                type="text"
                placeholder="Name"
              />
              <input
                required
                name="email"
                className="field"
                type="email"
                placeholder="Email address"
              />
              <textarea
                name="message"
                className="field resize-none"
                placeholder="Message"
                rows={6}
              />
              <button
                disabled={state !== "idle"}
                className="button mx-auto px-10 disabled:opacity-50"
              >
                {state === "idle" ? "Submit" : "Submitting…"}
              </button>
              <footer className="text-xs text-gray-500">
                The information provided above is not bound to any mail list
                subscription and shall only be used by us to contact you.
              </footer>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ButtonCreateCard;
