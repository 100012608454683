import { Logo } from "@wisecards/components";
import ButtonCreateCard from "@wisecards/site-components/src/ButtonCreateCard";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";

const AppLayout: React.FC = () => {
  return (
    <div className="flex min-h-screen flex-col">
      <Toaster />
      <header className="container my-2 mx-auto flex items-center px-2">
        <Logo className="w-40" />
        <ButtonCreateCard className="ml-auto" />
      </header>
      <Outlet />
      <footer className="container mx-auto py-2 px-2 text-gray-600">
        <small>Copyright © 2023 Wise Cards Ltd. All rights reserved.</small>
      </footer>
    </div>
  );
};

export default AppLayout;
