import logoSrc from "../assets/logo.svg";
import cx from "classnames";

const Logo: React.FC<{ className?: string }> = (props) => {
  return (
    <div className={cx("relative", props.className)}>
      <div className="pb-[20%]">
        <div
          className={cx("absolute inset-0 bg-contain bg-center bg-no-repeat")}
          style={{ backgroundImage: `url(${logoSrc})` }}
        />
      </div>
    </div>
  );
};

export default Logo;
